import cn from "classnames";

const Button = ({
  text,
  color,
  size,
  textSize,
  underline,
  sufix,
  link = "#",
}) => (
  <a href={link} target={'_blank'} rel={'noreferrer'}>
    <div
      className={cn(
        "grid justify-items-center rounded-full font-optum-sans-bold font-bold",
        {
          "py-3 px-14 w-full md:w-8/12": size === "sm",
          "py-4 px-16 w-10/12": size === "lg",
          "py-4 px-16 w-full": size === "full",
          "py-2 px-6 w-full sm:w-fit": !size,
        },
        {
          [`${textSize}`]: textSize,
          "text-size-regular": !textSize,
        },
        { "bg-optum-navy-blue text-optum-beige": color === "primary" },
        {
          "bg-warm-white text-blue-dark border border-blue-dark ":
            color === "secondary",
        },
        { "underline underline-offset-2": underline }
      )}
    >
      <div className="flex flex-rows gap-x-2">
        {text && <div>{text}</div>}
        {sufix && (
          <div className="grid justify-items-center items-center">{sufix}</div>
        )}
      </div>
    </div>
  </a>
);

export default Button;
