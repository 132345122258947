import SubHeader from "../Header/SubHeader/SubHeader";
import BestSouthland from "../../../assets/awards/best-southland.png";
import SanGabreielBalleys from "../../../assets/awards/san-gabriel-balleys.png";
import SouthBays from "../../../assets/awards/south-bays.png";
import ReadersBestChoice from "../../../assets/awards/readers-best-choice.png";
import PressTelegram from "../../../assets/awards/press-telegram.png";

const AwardsSection = () => (
  <div className="grid justify-center my-6">
    <SubHeader
      title="Awarded for excellent care in 2023"
      subTitleSize={"text-size-global-xl"}
      subTitle={`Optum was nationally and locally recognized for our care as "Best Medical Group" of the Southland by LA Times readers for the second year in a row.`}
    />
    <div className="grid gap-y-4 gap-x-16 my-10 mx-auto md:grid-cols-5">
      <img
        src={BestSouthland}
        width={135}
        height={135}
        alt={"The Best of the southland 2022"}
      />

      <img
        src={SanGabreielBalleys}
        width={135}
        height={135}
        alt={"San Gabriel Valley's Favorite 2002"}
      />

      <img
        src={SouthBays}
        width={135}
        height={135}
        alt={"South Bay's Favorite 2022"}
      />

      <img
        src={ReadersBestChoice}
        width={135}
        height={135}
        alt={"Readers Best Choice 2022"}
      />

      <img
        src={PressTelegram}
        width={135}
        height={135}
        alt={"Press-telegram Favorite 2022"}
      />
    </div>
  </div>
);

export default AwardsSection;
