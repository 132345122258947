import AboutUs from "./AboutUs/AboutUs";
import SubHeader from "../Header/SubHeader/SubHeader";
import Links from "./Links/Links";

const Footer = () => {
  return (
    <div className="bg-light-blue pt-8">
      <SubHeader title="For more information" />
      <AboutUs bgColor={"bg-light-blue"} />
      <Links />
    </div>
  );
};

export default Footer;
