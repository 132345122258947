import cn from "classnames";

const SubHeader = ({
  title,
  subTitle,
  titleColor,
  subTitleColor,
  subTitleSize,
  containterStyle,
}) => (
  <div
    className={cn("flex flex-col", {
      [`${containterStyle}`]: containterStyle,
      "p-5": !containterStyle,
    })}
  >
    {title && (
      <div
        className={cn(
          "grid justify-center text-center text-header-lg font-optum-sans-bold",
          {
            [`${titleColor}`]: titleColor,
            "text-blue-900": !titleColor,
          }
        )}
      >
        {title}
      </div>
    )}

    {subTitle && (
      <div
        className={cn(
          "grid justify-center pt-3 w-8/12 m-auto tracking-wide text-center",
          { [`${subTitleSize}`]: subTitleSize, "text-sm": !subTitleSize },
          {
            [`${subTitleColor}`]: subTitleColor,
            "text-neutral-600": !subTitleColor,
          }
        )}
      >
        {subTitle}
      </div>
    )}
  </div>
);

export default SubHeader;
