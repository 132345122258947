import cn from "classnames";
import IconCards from "./iconCards";

const IconCardsContainer = ({ info }) => {
  const {
    title,
    subtitle,
    subtitle2,
    cardLayout,
    cards,
    backgroundContainer,
    text,
  } = info;

  return (
    <div
      className={cn("grid gap-y-3 h-full font-optum-sans p-8", {
        "bg-optum-white": backgroundContainer === "white",
        "bg-optum-lightblue": backgroundContainer === "blue",
        "bg-optum-beige": backgroundContainer === "beige",
      })}
    >
      {/* Title */}
      {title && (
        <div className="grid justify-items-center font-optum-sans-bold text-header-md sm:text-header-lg md:text-header-lg lg:text-header-lg text-optum-navy-blue">
          {title}
        </div>
      )}

      {/* Subtitle */}
      {subtitle && (
        <h1 className="grid justify-items-center font-optum-sans md:text-size-global text-size-regular text-optum-gray">
          {subtitle}
        </h1>
      )}

      {/* Auxiliary Subtitle */}
      {subtitle2 && (
        <h1 className="grid justify-items-left font-optum-sans-bold text-header-sm md:text-header-md lg:text-header-lg text-optum-navy-blue">
          {subtitle2}
        </h1>
      )}

      {/* Auxiliary text */}
      {text && (
        <h1 className="grid justify-items-left font-optum-sans text-size-global text-optum-navy-blue">
          {text}
        </h1>
      )}

      <IconCards cards={cards} cardLayout={cardLayout} />
    </div>
  );
};

export default IconCardsContainer;
