import cn from "classnames";
import Title from "../../../UI/Title";

const HeroHeader = (props) => {
  const {
    height,
    title,
    titleColor,
    subtitle,
    subtitleColor,
    bgImagePosition,
    bgImageSize,
    bgColor,
    titleColStart,
    titleColEnd,
    containterStyle,
    justifyItems,
  } = props;

  return (
    <>
      <div
        className={cn(
          "grid grid-cols-12 bg-hero-pattern bg-no-repeat",
          {
            [`${bgImageSize}`]: bgImageSize,
            "bg-hero-pattern-lg-size 2xl:bg-hero-pattern-lg-size 3xl:bg-hero-pattern-2xl-size":
              !bgImageSize,
          },
          { [`${height}`]: height, "h-96": !height },
          {
            [`${bgImagePosition}`]: bgImagePosition,
            "bg-right-top": !bgImagePosition,
          },
          { [`${bgColor}`]: bgColor }
        )}
      >
        <div
          className={cn(
            "grid items-center",
            { "justify-items-center": justifyItems },
            {
              [`${titleColStart}`]: titleColStart,
              "col-start-1": !titleColStart,
            },
            {
              [`${titleColEnd}`]: titleColEnd,
              "col-end-5": !titleColEnd,
            }
          )}
        >
          <div
            className={cn({
              [`${containterStyle}`]: containterStyle,
              "p-12": !containterStyle,
            })}
          >
            {title && <Title title={title} titleColor={titleColor} />}
            {subtitle && (
              <span
                className={cn("text-xs font-optum-sans-bold leading-6", {
                  [`${subtitleColor}`]: subtitleColor,
                  "text-slate-50": !subtitleColor,
                })}
              >
                {subtitle}
              </span>
            )}
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroHeader;
