import cn from "classnames";
import Button from "../Button";

const TextRow = ({ info }) => {
  const {
    title,
    subtitle,
    buttonText,
    buttonLink,
    buttonColor,
    widthTitle,
    backgroundColor,
    text,
  } = info;

  return (
    <div
      className={cn("grid gap-y-3 h-full font-optum-sans sm:p-8", {
        [`${backgroundColor}`]: backgroundColor,
        "bg-optum-beige": !backgroundColor,
      })}
    >
      <div
        className={`grid grid-cols-1 justify-items-center h-full w-full p-8`}
      >
        {title && (
          <div
            className={cn(
              "my-4 text-center font-optum-sans-bold text-optum-navy-blue text-header-xl",
              {
                [`${widthTitle}`]: widthTitle,
                "max-w-full": !widthTitle,
              }
            )}
          >
            {title}
          </div>
        )}
        {subtitle && (
          <div
            className={
              "mb-2 font-optum-sans-bold text-optum-navy-blue text-size-global"
            }
          >
            {subtitle}
          </div>
        )}
        {text && (
          <div
            className={"my-4 font-optum-sans text-optum-n80 text-size-regular"}
          >
            {text}
          </div>
        )}
        {buttonText && (
          <div className={"my-4"}>
            <Button text={buttonText} color={buttonColor} link={buttonLink} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TextRow;
