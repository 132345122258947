import cn from "classnames";
import IconCard from "./iconCard";

const IconCards = ({ cards, cardLayout }) =>
  cardLayout && (
    <div
      className={cn("grid grid-cols-1 mt-4", {
        "gap-4 sm:grid-cols-2 lg:grid-cols-4": cardLayout === "4x1",
        "gap-4 sm:grid-cols-2 lg:grid-cols-2 lg:gap-x-16": cardLayout === "2x2",
        "gap-y-4 gap-x-20 sm:grid-cols-1 lg:grid-cols-3": cardLayout === "3x1",
      })}
    >
      {cards &&
        cards.map((card, index) => {
          return (
            <IconCard
              key={`card-${cardLayout}-${index}`}
              card={card}
              cardLayout={cardLayout}
            />
          );
        })}
    </div>
  );

export default IconCards;
