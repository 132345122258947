import optumLogo from "../../../../assets/svg/optumLogo.svg";

const NavBar = () => {
  return (
    <div className="grid grid-cols-8 px-5 py-2 sm:px-12 sm:py-8">
      <div className="col-start-1 col-end-3 text-4xl mt-2 sm:mt-0 font-optum-sans-Xbold font-bold text-orange-500">
        <img src={optumLogo} width={123} height={48} alt="Optum Logo" />
      </div>
    </div>
  );
};

export default NavBar;
