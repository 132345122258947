import Footer from "../../Components/Home/Footer/Footer";
import Header from "../../Components/Home/Header/Header";
import AwardsSection from "../../Components/Home/Awards/AwardsSection";
import HighlightSection from "../../Components/Home/Highlight/HighlightSection";
import TextRow from "../../Components/UI/text-row/textRow";
import IconCardsSection from "../../Components/Home/IconCards/IconCardsSection";
import { useParams } from "react-router-dom";

const HomePage = () => {
  const { region } = useParams();

  return (
    <div className="App font-optum-sans bg-white">
        <head>
            <meta name="robots" content="noindex,nofollow" key={"norobots"} />
            <meta name="googlebot" content="noindex,nofollow" key={'googlebot'} />
            <meta name="google" content="nositelinkssearchbox" key={'sitelinks'} />
        </head>
      <Header region={region} />
      <HighlightSection />
      <IconCardsSection />
      <TextRow
        info={{
          title: "We accept most major HMO, PPO and Medicare health plans",
          buttonText: "View accepted health plans",
          buttonLink:
            "https://www.optum.com/care/locations/california/optum-california/health-insurance-plans.html",
          buttonColor: "secondary",
          widthTitle: "max-w-3xl",
          backgroundColor: "bg-light-blue",
        }}
      />
      <AwardsSection />
      <Footer />
    </div>
  );
};

export default HomePage;
