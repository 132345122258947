export const BACKGROUND_POSITIONS_CLASSES = {
  rightTop: "bg-right-top",
  center: "bg-center",
  right: "bg-right",
};

export const BACKGROUND_SIZE_CLASSES = {
  sm: "bg-hero-pattern-sm-size",
};

export const VALID_REGIONS = [
  "Long Beach",
  "Los Angeles",
  "San Gabriel Valley",
  "San Fernando Valley",
  "South Bay",
  "Orange County",
];
