import OptumLink from "./OptumLink";

const OptumLinks = () => (
  <div className="flex flex-col items-stretch justify-items-start items-center w-full gap-x-2 gap-y-4 py-8 mx-auto text-sm font-optum-sans tracking-wide md:grid md:grid-cols-7 md:h-36 lg:grid-row">
    <div className="col-span-2">© 2023 Optum, Inc. All rights reserved</div>
    <OptumLink
      text={"Privacy Policy"}
      link={"https://www.optum.com/privacy-policy.html"}
    />
    <OptumLink
      text={"Terms of Use"}
      link={"https://www.optum.com/terms-of-use.html"}
    />
    <OptumLink text={"Opt out"} link={"https://www.optum.com/opt-out.html"} />
    <OptumLink
      text={"Accessibility"}
      link={"https://www.optum.com/accessibility1.html?s=optum"}
    />
    <OptumLink
      text={"Vulnerability report"}
      link={"https://www.optum.com/vulnerability.html"}
    />
  </div>
);

export default OptumLinks;
