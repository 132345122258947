import cn from "classnames";

const AboutUs = ({ bgColor }) => {
  return (
    <>
      <div
        className={cn(
          "flex flex-col h-auto p-12 gap-x-16 gap-y-4 md:grid grid-cols-3",
          {
            [`${bgColor}`]: bgColor,
            "bg-blue-light": !bgColor,
          }
        )}
      >
        <div className="flex flex-col gap-y-4 p-5 bg-white border border-inherit rounded-md">
          <div className="font-optum-sans-bold text-blue-dark text-xl">
            About us
          </div>
          <div className="tracking-wide">
            We create a healthier world - one insight, one connection, one
            person at a time. See how
          </div>
          <div className="text-blue-dark text-base font-optum-sans-bold">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.optum.com/about-us.html"
            >
              See how <span className="ml-4">&gt;</span>
            </a>
          </div>
        </div>

        <div className="flex flex-col gap-y-4 p-5 bg-white border border-inherit rounded-md">
          <div className="font-optum-sans-bold text-blue-dark text-xl">
            Individuals and families
          </div>
          <div className="tracking-wide">
            If you're already a member, call the number on your membership card.
          </div>
          <div className="text-blue-dark text-base font-optum-sans-bold">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.optum.com/business.html"
            >
              View contact options <span className="ml-4">&gt;</span>
            </a>
          </div>
        </div>

        <div className="flex flex-col gap-y-4 bg-white p-5 border border-inherit rounded-md">
          <div className="font-optum-sans-bold text-blue-dark text-xl">
            Industry professionals
          </div>
          <div className="tracking-wide">
            Need help? Find phone numbers, emails, websites and more to assist
            you.
          </div>
          <div className="text-blue-dark text-base font-optum-sans-bold">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.optum.com/care.html"
            >
              View contact options <span className="ml-4">&gt;</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
