const OptumLink = ({ text, link }) => (
  <div className="grid justify-items-start md:pl-10">
    <a
      target="_blank"
      rel="noreferrer"
      className="hover:underline underline-offset-1"
      href={link}
    >
      {text}
    </a>
  </div>
);

export default OptumLink;
