import LanguageAssistance from "./LanguageAssistance";
import OptumLinks from "./OptumLinks";

const Links = () => (
  <div className="h-fit bg-white pt-12 text-davys-gray-100">
    <div className="flex flex-col my-auto mx-auto px-4 w-11/12">
      <LanguageAssistance />
      <OptumLinks />
    </div>
  </div>
);

export default Links;
