import Highlight from "../../UI/image-besides-text/highlight";

import imgPlaceholder from "../../../assets/images/placeholder.png";

const HighlightSection = () => (
  <Highlight
    info={{
      buttonLink: "/#",
      backgroundColor: "white",
      image: imgPlaceholder,
      buttonColorClass: "primary",
      isLeft: true,
    }}
  >
    <div
      className={
        "grid items-center gap-8 font-optum-sans text-optum-n80 text-size-global p-5 "
      }
    >
      <div
        className={
          "font-optum-sans-bold text-optum-navy-blue text-header-lg lg:pr-20"
        }
      >
        Helping you get the right care at the right time with:
      </div>
      <ul className="pl-6 list-disc">
        <li>A wide range of specialists and quick referrals</li>
        <li>Large network of urgent care centers</li>
        <li>
          Drive-thru and walk-in clinics for flu shots and annual checkups
        </li>
      </ul>
    </div>
  </Highlight>
);

export default HighlightSection;
