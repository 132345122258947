const LanguageAssistance = () => (
  <div className="grid grid-rows-3 md:grid-cols-3 gap-4 md:justify-items-center w-full text-size-regular font-optum-sans-bold">
    <a href="https://www.optum.com/language-assistance-nondiscrimination.html">
      <div>
        Language Assistance / <br className="hidden md:inline" />
        Non-Discrimination Notice
      </div>
    </a>
    <a href="https://www.optum.com/language-assistance-nondiscrimination.html">
      <div>
        Asistencia de Idiomas / <br className="hidden md:inline" />
        Aviso de no Discriminación
      </div>
    </a>
    <a href="https://www.optum.com/language-assistance-nondiscrimination.html">
      <div>語言協助 / 不歧視通知</div>
    </a>
  </div>
);

export default LanguageAssistance;
