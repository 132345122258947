import cn from "classnames";

const IconCard = ({ card, cardLayout }) => (
  <div
    className={cn(
      `grid h-full w-full p-6 text-optum-n80 text-size-regular lg:justify-items-start justify-items-center`,
      { "justify-items-start": cardLayout === "3x1" },
      { "border rounded border-optum-card-border": card.border },
      {
        "bg-optum-beige": card.backgroundColor === "beige",
        "bg-optum-lightblue": card.backgroundColor === "blue",
        "bg-optum-white": card.backgroundColor === "white",
      }
    )}
  >
    {card.icon && (
      <img src={card.icon} alt={"icon"} width={40} height={40} priority />
    )}
    {card.title && (
      <div
        className={cn("my-4 font-optum-sans-bold text-optum-navy-blue", {
          [`${card.titleSize}`]: card.titleSize,
          "text-header-xsm": !card.titleSize,
        })}
      >
        {card.title}
      </div>
    )}
    {card.subtitle && (
      <div
        className={cn(
          "font-optum-sans-bold text-optum-navy-blue text-size-global",
          { "my-1": cardLayout === "4x1" },
          { "my-4": cardLayout === "2x2" }
        )}
      >
        {card.subtitle}
      </div>
    )}
    {card.text && (
      <div
        className={cn(
          "font-optum-sans mt-2",
          {
            "text-optum-navy-blue":
              cardLayout === "4x1" && card.backgroundColor === "blue",
          },
          {
            "mt-1 text-optum-navy-blue text-size-small pr-0 lg:text-size-regular lg:pr-10 xl:pr-32":
              cardLayout === "2x2",
          }
        )}
      >
        {card.text}
      </div>
    )}
  </div>
);

export default IconCard;
