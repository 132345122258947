import {
  BACKGROUND_POSITIONS_CLASSES,
  BACKGROUND_SIZE_CLASSES,
  VALID_REGIONS,
} from "../../../Constants/general";
import HeroHeader from "./HeroHeader/HeroHeader";
import NavBar from "./NavBar/NavBar";
import SubHeader from "./SubHeader/SubHeader";
import Button from "../../UI/Button";
import OpenIcon from "../../../assets/icons/open-in-new.png";
import { useEffect, useRef, useState } from "react";

const Header = ({ region, prevailOnUnmount = false }) => {
  const [headerTitle, setHeaderTitle] = useState(
    "We’re welcoming </br> new patients"
  );

  const defaultTitle = useRef(document.title);

  useEffect(() => {
    if (VALID_REGIONS.includes(region)) {
      setHeaderTitle(`We’re welcoming </br> new patients in </br> ${region}`);

      document.title = `Welcoming new patients in ${region} | Optum`;
    }
  }, [region]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [prevailOnUnmount]
  );

  return (
    <>
      <NavBar />
      <HeroHeader
        title={headerTitle}
        height="h-39rem"
        titleColor="text-optum-navy-blue"
        bgImagePosition={BACKGROUND_POSITIONS_CLASSES.right}
        bgImageSize={BACKGROUND_SIZE_CLASSES.sm}
        bgColor="bg-warm-white"
        titleColEnd="col-end-13 md:col-end-10 lg:col-end-8"
        containterStyle={"flex flex-col p-4 text-center md:text-left lg:p-14"}
        justifyItems={false}
      >
        <div className="max-md:grid max-md:justify-items-center mt-6">
          <Button
            text={"Find a doctor near you"}
            color={"primary"}
            link={
              "https://www.optum.com/care/locations/california/optum-california.html"
            }
            size={"sm"}
            textSize={"text-size-global"}
            sufix={
              <img
                width={18}
                height={18}
                src={OpenIcon}
                alt="Open in new icon"
              />
            }
            underline
          />
        </div>
      </HeroHeader>
      <SubHeader
        title="Great health starts with a trusted doctor"
        subTitle="Your care should be all about you. With Optum, you have a doctor who works with a team to support your health goals."
        titleColor="text-optum-navy-blue"
        subTitleColor="text-davys-gray"
        subTitleSize="text-header-sm"
        containterStyle="my-6 p-5"
      />
    </>
  );
};

export default Header;
