import Button from "../Button";

const Highlight = (props) => {
  const {
    title,
    subtitle,
    buttonText,
    buttonLink,
    image,
    isLeft,
    buttonColorClass,
    text,
    widthImage,
    heightImage,
  } = props.info;

  return (
    <div className={`grid gap-y-3 font-optum-sans p-8`}>
      <div className="grid gap-y-4 gap-x-4 mt-4 lg:grid-cols-2">
        {isLeft && (
          <div className={`grid auto-rows-auto w-full p-6`}>
            {image && (
              <img
                style={{
                  width: `${widthImage}px`,
                  height: `${heightImage}px`,
                }}
                src={image}
                alt={"Optum Placeholder"}
                priority
              />
            )}
          </div>
        )}
        <div
          className={`grid items-center text-center gap-y-4 w-full text-optum-n80 text-size-regular lg:p-8 lg:text-left`}
        >
          {title && (
            <div
              className={
                "mt-5 font-optum-sans-bold text-optum-navy-blue text-header-lg lg:pr-20"
              }
            >
              {title}
            </div>
          )}
          {subtitle && (
            <div
              className={
                "mb-2 font-optum-sans-bold text-optum-navy-blue text-size-global"
              }
            >
              {subtitle}
            </div>
          )}
          {text && (
            <div
              className={
                "mt-10 font-optum-sans text-optum-n80 text-size-regular"
              }
            >
              {text}
            </div>
          )}
          {props.children}
          {buttonText && (
            <div className={"my-20"}>
              <Button
                text={buttonText}
                color={buttonColorClass}
                link={buttonLink}
              />
            </div>
          )}
        </div>
        {!isLeft && (
          <div className={`grid justify-items-center h-full w-full p-6`}>
            {image && (
              <img
                src={image}
                alt={"Optum Placeholder"}
                width={widthImage}
                height={heightImage}
                priority
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Highlight;
