import IconCardsContainer from "../../UI/icon-cards/iconCardsContainer";
import SubHeader from "../Header/SubHeader/SubHeader";
import PrimaryCareIcon from "../../../assets/icons/primary-care.png";
import SpecialtyIcon from "../../../assets/icons/specialty-doctors.png";
import UrgentCareIcon from "../../../assets/icons/urgent-care-centers.png";
import AffiliatedIcon from "../../../assets/icons/affiliated-hospitals.png";
import Button from "../../UI/Button";

const IconCardsSection = () => (
  <>
    <SubHeader
      title="We care for over 2 million patients in Southern California"
      titleColor="text-optum-navy-blue"
      subTitleColor="text-davys-gray"
      subTitleSize="text-lg"
      containterStyle="my-6 p-5"
    />
    <div className="grid justify-items-stretch mx-auto lg:pl-14 lg:ml-14">
      <IconCardsContainer
        info={{
          backgroundContainer: "white",
          cardLayout: "4x1",
          cards: [
            {
              id: 1,
              icon: PrimaryCareIcon,
              title: "3,700+",
              titleSize: "text-header-lg",
              text: "primary care doctors",
              backgroundColor: "white",
            },
            {
              id: 2,
              icon: SpecialtyIcon,
              title: "11,000+",
              titleSize: "text-header-lg",
              text: "specialty doctors",
              backgroundColor: "white",
            },
            {
              id: 3,
              icon: UrgentCareIcon,
              title: "100+",
              titleSize: "text-header-lg",
              text: "urgent care centers",
              backgroundColor: "white",
            },
            {
              id: 4,
              icon: AffiliatedIcon,
              title: "100+",
              titleSize: "text-header-lg",
              text: "affiliated hospitals",
              backgroundColor: "white",
            },
          ],
        }}
      />
    </div>

    <div className="grid justify-items-center items-center h-24 w-full">
      <Button
        text={"Learn more about Optum CA"}
        color={"secondary"}
        textSize={"text-size-global"}
        size={"full"}
        link={"https://www.optum.com/about-us.html"}
      />
    </div>
  </>
);

export default IconCardsSection;
