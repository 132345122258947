import cn from "classnames";

const Title = ({ titleColor, title }) => (
  <div
    className={cn(
      "text-header-xl font-optum-sans-bold max-md:bg-blue-aqua/[0.3] lg:pb-4",
      {
        [`${titleColor}`]: titleColor,
        "text-slate-50": !titleColor,
      }
    )}
    dangerouslySetInnerHTML={{ __html: title }}
  />
);

export default Title;
